.Card {
  background: linear-gradient(#121212, #121212);
  color: #fff;
  box-shadow: 0 0 0 3px rgba(0,0,0,.5), inset 0 2px 2px 1px hsla(0,0%,100%,.11);
  transition: background 0.2s;
  border-radius: 4px;
  overflow: hidden;
  width: 192px;
  height: 256px;
  display: grid;
  grid-template-rows: 1fr 4fr 4fr;
}
@media (max-height: 768px) {
  .Card {
    height: 224px;
  }
}

.Card:hover {
  background: linear-gradient(#151515, #202020);
}

.Card.Correct > .Heading {
  color: var(--green);
  font-variation-settings: 'FILL' 1;
}
.Card.Incorrect > .Heading {
  color: var(--red);
}

.Card > .Heading > * > .material-symbols-rounded {
  font-size: 18px;
}

.Card > .Heading {
  position: relative;
  padding: 4px 32px 0;
  margin-bottom: 4px;
}
.Card > .Heading > div {
  position: relative;
  z-index: 1;
}
.Card > .Heading::before {
  content: ' ';
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  padding: 4px 8px 4px;
  width: 174px;
  height: 22px;
  background-clip: content-box;
  background-image: radial-gradient(#444 1px, transparent 0);
  background-size: 6px 6px;
  background-position: -3px -3px;
  transition: opacity 0.2s;
  opacity: 0;
}
.Card.Draggable > .Heading::before {
  opacity: 1;
}
.Card.Draggable {
  /*animation: wiggle 0.5s ease infinite;*/
}
@keyframes wiggle {
  0% {
    margin-top: 1px;
    transform: rotate(0.6deg);
  }
  25% {
    transform: rotate(-0.8deg);
  }
  50% {
    margin-top: -1px;
    transform: rotate(1.2deg);
  }
  75% {
    transform: rotate(-0.5deg);
  }
  100% {
    margin-top: 1px;
    transform: rotate(0.5deg);
  }
}

.Card > .Heading > div {
  text-align: center;
}

.Card > .Image {
  flex-grow: 1;
  margin: 0 8px 8px;
  width: calc(100% - 16px);
  height: calc(100% - 8px);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.Card > .Image.Backgrounded {
  background-color: hsl(0, 0%, 90%);
}

.Card > .CardContent {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.Card > .CardContent > .Background {
  width: 100%;
  height: 100%;
  background-color: white;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  z-index: 0;
}

.Card > .CardContent > .Text {
  height: 100%;
  padding: 4px 8px;
  margin: 0 8px;
  z-index: 1;
  background-color: #000;
  border-radius: 4px;
}

.Card > .CardContent > .Text > .Title {
  font-weight: 700;
  font-size: 18px;
}
@media (max-height: 768px) {
  .Card > .CardContent > .Text > .Title {
    font-size: 14px;
  }
}

.Card > .CardContent > .Text > .Description {
  font-size: 11px;
  font-weight: 500;
}

.Card > .CardContent > .Date {
  background-color: var(--blue);
  color: var(--blue-light);
  border-radius: 4px;
  margin: 8px;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  transition: background-color 0.2s;
  z-index: 1;
}

.Card.Initial > .CardContent > .Date {
  background-color: var(--gray);
  color: #fff;
}

.Card.Correct > .CardContent > .Date {
  background-color: var(--green);
  color: var(--green-light);
}

.Card.Incorrect > .CardContent > .Date {
  background-color: var(--red);
  color: var(--red-light);
}

.Card > .CardContent > .Date.Linked {
  text-decoration: underline;
  cursor: pointer;
}
