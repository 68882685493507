:root {
  --green: #08863f;
  --green-half: #0e9a4b;
  --green-light: #c8e7d5;
  --red: #8d0f0f;
  --red-light: #f5e1e1;
  --blue: #0542be;
  --blue-light: #c2d0ec;
  --gray: #282c34;
}

html {
  background-color: #121212;
  overscroll-behavior: none;
  user-select: none;
  height: 100%;
  overflow: hidden;
  color: #fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
