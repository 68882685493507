dialog {
  max-width: min(calc(100vw - 64px), 612px);
  padding: 8px;
  background-color: #121212;
  color: #fff;
  border-radius: 4px;
  border: none;
  box-shadow: 0 0 0 3px rgba(0,0,0,.5), inset 0 2px 2px 1px hsla(0,0%,100%,.11);
}
dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
dialog > #close {
  cursor: pointer;
  float: right;
}
dialog > h2, dialog > h3 {
  margin: 0;
  padding: 8px;
}
dialog > section {
  background-color: #000;
  padding: 4px 8px;
  border-radius: 4px;
}
dialog > section > * > .material-symbols-rounded {
  font-variation-settings: 'FILL' 1;
  font-size: 12px;
}

.HeaderContainer {
  width: 100vw;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.Header {
  font-size: 24px;
  font-weight: 500;
  display: grid;
  align-items: center;
  padding: 16px;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1040px;
  flex-grow: 1;
}

.Header > *:first-child {
  text-align: left;
}

.Header > *:last-child {
  text-align: right;
}

.Header > *:nth-child(2) {
  font-size: 32px;
  text-align: center;
}

.Header > .Icons {
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
}

.Header > .Icons > span {
  width: fit-content;
  cursor: pointer;
  font-size: 32px;
  padding: 4px;
  transition: background-color 0.2s, outline 0.2s;
  border-radius: 100px;
  outline: 0 solid #1c1c1c;
}
@media (max-height: 768px) {
  .Header > .Icons > span {
    font-size: 24px;
  }

  .Header > .Icons {
    grid-column-gap: 4px;
  }
}

.Header > .Icons > span:hover {
  background-color: #1c1c1c;
  outline: 4px solid #1c1c1c;
}

.GrabZone {
  height: 272px;
}
@media (max-height: 768px) {
  .GrabZone {
    height: 240px;
  }
}

.DragHint {
  position: absolute;
  bottom: -64px;
  font-size: 48px;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(82,82,82,1), rgba(0, 0, 0, 0));
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient 3s ease infinite;
}
@media (max-height: 768px) {
  .DragHint {
    bottom: -22px;
    font-size: 28px;
  }
}

@keyframes gradient {
  0% {
    background-position: 0 200%;
  }
  60% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 0;
  }
}

.Line {
  position: fixed;
  background: linear-gradient(0deg, rgba(32,32,32,1) 0%, rgba(16,16,16,1) 100%);
  height: 34px;
  width: 100%;
  min-width: 480px;
  border-top: 2px solid rgba(18,18,18,.4);
  border-bottom: 3px solid rgba(0,0,0,.6);
  margin-bottom: -6px;
}
@media (min-height: 768px) {
  .Line {
    margin-bottom: -70px;
    height: 98px;
  }
}

.Legend {
  position: fixed;
  margin-bottom: 40px;
  width: calc(100% - 16px);
  max-width: 612px;
  display: flex;
  justify-content: space-between;
  color: #555;
  font-weight: 500;
}

.Legend > span {
  display: flex;
  align-items: center;
}

.Legend > span > span {
  font-weight: 500;
  font-size: 20px;
  display: none;
}

.Board {
  left: 0;
  height: calc(282px - 4px);
  width: 100vw;
  overflow: hidden;
  position: absolute;
  display: flex;
  align-items: flex-end;
  z-index: -1;
  justify-content: center;
}
@media (max-height: 768px) {
  .Board {
    height: calc(250px - 4px);
  }
}

.BoardContainer {
  display: flex;
  width: 100vw;
  overflow-y: auto;
  padding-bottom: 64px;
}

@media (max-height: 768px) {
  .BoardContainer {
    padding-bottom: 0;
  }
}

.DropZone {
  height: 272px;
}
@media (max-height: 768px) {
  .DropZone {
    height: 240px;
  }
}
