.ShareButton {
    box-shadow: 0 0 0 3px rgba(0,0,0,.5), inset 0 2px 1px hsla(0,0%,100%,.2);
    background-color: var(--green);
    padding: 4px 32px;
    flex-direction: row;
    align-items: center;
    border-radius: 80px;
    cursor: pointer;
    display: flex;
}

.ShareButton > span:not(.material-symbols-rounded) {
    padding-bottom: 3px;
}

.ShareButton:hover {
    background: linear-gradient(var(--green), var(--green-half));
}
