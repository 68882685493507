.GameEnd {
    background-color: rgba(26,26,26,1);
    border-radius: 4px;
    padding: 16px;
    width: 192px;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: center;
}

.GameEnd > h3 {
    margin: 0;
}

.GameEnd.Stars {
    display: flex;
    align-items: center;
}
.GameEnd.Stars > span {
    line-height: 0;
}

.GameEnd > .Stars.Correct {
    color: var(--green-light);
}

.GameEnd > .Stars.Incorrect {
    color: var(--red-light);
}

.GameEnd > * > .material-symbols-rounded {
    font-variation-settings: 'FILL' 1;
    font-size: 16px;
    margin-right: 8px;
}
